import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import './App.css';

import ErcSms from './ercSms/ercSms';
import Ivr from './ivr/ivr';

export default class App extends React.Component {
  render(){
    return (
      <>
        <Router>
          <Switch>
            <Route path="/ercSms">
              <ErcSms></ErcSms>
            </Route>
            <Route path="/ivr">
              <Ivr></Ivr>
            </Route>
            <Route path="/">
              <ErcSms></ErcSms>
            </Route>
          </Switch>
        </Router>
      </>
    );
  }
}
