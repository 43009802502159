import React from "react";
import { Container, View } from "@amzn/stencil-react-components/layout";
import { H1, P } from "@amzn/stencil-react-components/text";
import "./ercSms.css";

export default class ErcSms extends React.Component {
  render() {
    return (
      <>
        <Container paddingTop="18px" paddingHorizontal="18px">
          <H1 color="primary70">ERC SMS Terms of Service</H1>
          <P color="primary70">Last updated: 1/15/2021</P>
          <View backgroundColor="neutral10" padding="S300">
            <ul>
              <P>
                The ERC SMS service sends one-time text notifications providing
                important employment related information to the employee. This
                includes Wisely card funding updates, missing or additional
                documentation needed, and expiring Work Authorizations.
              </P>
              <P>
                You opt-in to this service by giving verbal consent to your
                Employee Services Agent. Below is the opt-in process:
              </P>
              <ol>
                <P>1. Research and process the instant issue as normal.</P>

                <P>
                  2. Offer Text FIRST as a notification option by saying, “Would
                  you like to receive a text from ERC when your Wisely card has
                  been funded? Please note that message and data rates may
                  apply. You can access our Terms and Conditions and privacy
                  policy at any time at{" "}
                  <a href="https://d1mtev92n3witm.cloudfront.net/">
                    Terms and Conditions
                  </a>
                  . What phone number would you like this to go to?
                </P>
                <P>
                  3. If the employee says YES: ask for a phone number that
                  accepts text and note the phone number in the Panorama Case
                  Description.
                </P>
                <P>
                  4. If the employee says NO: offer Email, HR Case, or Call Back
                  as normal and contact the employee through the requested
                  method once the ticket has been resolved. Use the ‘SMS Opt-out
                  – Attendance Points’ Contact Driver when resolving the case.
                </P>
              </ol>

              <P>
                Consenting to receive text and voice messages is not required to
                continue employment with Amazon, and you can stop receiving
                messages by replying "STOP" to any message that you receive. We
                will send you an SMS message to confirm that you have been
                unsubscribed. After this, you will no longer receive SMS
                messages from us. If you want to join again, just sign up as you
                as you did the first time and we will start sending SMS messages
                to you again.
              </P>
              <P>
                If you are experiencing issues with the messaging program you
                can reply with the keyword "HELP" for more assistance. After you
                send the SMS message "HELP" to us, we will respond with
                instructions on how to use our service as well as how to
                unsubscribe.
              </P>
              <P>
                We are able to deliver messages to the following mobile phone
                carriers: Major Carriers: AT&T, Verizon Wireless, Sprint,
                T-Mobile. Minor Carriers: U.S. Cellular, Boost Mobile, MetroPCS,
                Virgin Mobile, Alaska Communications Systems (ACS), Appalachian
                Wireless (EKN), Bluegrass Cellular, Cellular One of East
                Central, IL (ECIT), Cellular One of Northeast Pennsylvania,
                Cricket, Coral Wireless (Mobi PCS), COX, Cross, Element Mobile
                (Flat Wireless), Epic Touch (Elkhart Telephone), GCI, Golden
                State, Hawkeye (Chat Mobility), Hawkeye (NW Missouri), Illinois
                Valley Cellular, Inland Cellular, iWireless (Iowa Wireless),
                Keystone Wireless (Immix Wireless/PC Man), Mosaic (Consolidated
                or CTC Telecom), Nex-Tech Wireless, NTelos, Panhandle
                Communications, Pioneer, Plateau (Texas RSA 3 Ltd), Revol, RINA,
                Simmetry (TMP Corporation),Thumb Cellular, Union Wireless,
                United Wireless, Viaero Wireless, and West Central (WCC or 5
                Star Wireless).
              </P>
              <P>
                Carriers are not liable for delayed or undelivered messages.
              </P>
              <P>
                As always, Message and Data Rates May Apply for any messages
                sent to you from us and to us from you. If you have any
                questions about your text plan or data plan, it is best to
                contact your wireless provider. For all questions about the
                services provided by this short code, you can send an email to
                your company’s support email address.
              </P>
              <P>
                If you have any questions regarding privacy, see your company’s
                privacy policy.
              </P>
            </ul>
          </View>
        </Container>
      </>
    );
  }
}
