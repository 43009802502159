import React from "react";
import { Container, View } from "@amzn/stencil-react-components/layout";
import { H1, P } from "@amzn/stencil-react-components/text";
import "./ivr.css";

export default class Ivr extends React.Component {
  render() {
    return (
      <>
        <Container paddingTop="18px" paddingHorizontal="18px">
          <H1 color="primary70">ERC SMS IVR Terms of Service</H1>
          <P color="primary70">Last updated: 3/8/2021</P>
          <View backgroundColor="neutral10" padding="S300">
            <ul>
              <P>
                The ERC IVR to SMS service sends one-time text notifications
                providing important employment related information to the
                employee. This includes information on how to sign in to company
                portals, how to register for vaccine-related benefits, and other
                employment-related information.
              </P>
              <P>
                You opt-in to this service by pressing 1 at an SMS opt-in prompt
                in the IVR. Below is the opt-in process:
              </P>
              <ol>
                <P>1. Caller reaches an SMS-enabled flow.</P>
                <P>
                  2. FAQ information plays, and caller is offered an SMS with
                  more information "Would you like to receive this information
                  via text? Press 1 to receive a text. Message and data rates
                  may apply."
                </P>
                <P>
                  3. If caller opts in, the IVR will confirm that the caller
                  wants the SMS sent to their current phone number or a
                  different number.
                </P>
                <P>
                  4. The caller opts-in on the number they're calling from or
                  enters a new number.
                </P>
                <P>5. The SMS message is sent to the number provided.</P>
                <P>
                  6. If the caller does not press 1 to opt-in to an SMS message,
                  they will not receive an SMS message.
                </P>
                <P>
                  7. This opt-in is strictly used for the one-time message. The
                  opt-in is not stored and used for sending future SMS messages.
                </P>
              </ol>
              <P>
                Consenting to receive text and voice messages is not required to
                continue employment with Amazon, and you can stop receiving
                messages by replying "STOP" to any message that you receive. We
                will send you an SMS message to confirm that you have been
                unsubscribed. After this, you will no longer receive SMS
                messages from us. If you want to join again, just sign up as you
                as you did the first time and we will start sending SMS messages
                to you again.
              </P>
              <P>
                If you are experiencing issues with the messaging program you
                can reply with the keyword "HELP" for more assistance. After you
                send the SMS message "HELP" to us, we will respond with
                instructions on how to use our service as well as how to
                unsubscribe.
              </P>
              <P>
                We are able to deliver messages to the following mobile phone
                carriers: Major Carriers: AT&T, Verizon Wireless, Sprint,
                T-Mobile. Minor Carriers: U.S. Cellular, Boost Mobile, MetroPCS,
                Virgin Mobile, Alaska Communications Systems (ACS), Appalachian
                Wireless (EKN), Bluegrass Cellular, Cellular One of East
                Central, IL (ECIT), Cellular One of Northeast Pennsylvania,
                Cricket, Coral Wireless (Mobi PCS), COX, Cross, Element Mobile
                (Flat Wireless), Epic Touch (Elkhart Telephone), GCI, Golden
                State, Hawkeye (Chat Mobility), Hawkeye (NW Missouri), Illinois
                Valley Cellular, Inland Cellular, iWireless (Iowa Wireless),
                Keystone Wireless (Immix Wireless/PC Man), Mosaic (Consolidated
                or CTC Telecom), Nex-Tech Wireless, NTelos, Panhandle
                Communications, Pioneer, Plateau (Texas RSA 3 Ltd), Revol, RINA,
                Simmetry (TMP Corporation),Thumb Cellular, Union Wireless,
                United Wireless, Viaero Wireless, and West Central (WCC or 5
                Star Wireless).
              </P>
              <P>
                Carriers are not liable for delayed or undelivered messages.
              </P>
              <P>
                As always, Message and Data Rates May Apply for any messages
                sent to you from us and to us from you. If you have any
                questions about your text plan or data plan, it is best to
                contact your wireless provider. For all questions about the
                services provided by this short code, you can send an email to
                your company’s support email address.
              </P>
              <P>
                If you have any questions regarding privacy, see your company’s
                privacy policy.
              </P>
            </ul>
          </View>
        </Container>
      </>
    );
  }
}
